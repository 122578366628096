<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import BilanCircuit from "@views/bilan/circuit/components/BilanCircuit";
import Toast from "primevue/toast";
import AkInputText from "@components/input/AkInputText";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCircuitService from "@services/bilanCircuitService";
import circuitService from "@services/circuitService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormView, AkFormSubmitted, AkInputText, BilanCircuit, Toast},
  mixins: [randomRef, roleMixin],
  metaInfo() {
    return {
      title: "bilan.update",
    }
  },
  data() {
    return {
      submitted: false,
      circuitList: [],
      current: {},
      currentGeneral: {},
      copy: {
        data: null,
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p1 = circuitService.findByCeiId(this.$route.params.id);
    p1.then(data => this.circuitList = data);

    let p2 = bilanCircuitService.findByCeiId(this.$route.params.id);
    p2.then(data => this.current = data);
  
    let p3 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.circuitReview.edit);

    Promise.all([p1, p2, p3]).then(()=>{
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      this.currentGeneral.ager = this.circuitList[0].agerLabel;
      this.currentGeneral.uer = this.circuitList[0].uerLabel;
      this.currentGeneral.cei = this.circuitList[0].ceiLabel;
      for (const element of this.current) {
        for (const circuit of this.circuitList) {
          if (element.circuitId === circuit.id) {
            element.number = circuit.number;
            element.axis = circuit.axis;
          }
        }
      };
      // retirer les current qui ont une valeur dans year (ce sont les anciennes données qui sont archivées)
      this.current = this.current.filter(c => c.year === null);
      this.current.sort((a,b) => {
        return a.number.localeCompare(b.number);
      });
    },
    update() {
      this.getRef().showLoader(this.$refs.form)
      
      let promises = [];
      for (const element of this.current) {
        let p = bilanCircuitService.update(element);
        promises.push(p);
        p.catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
          this.validationSubmitted = false;
        });
      }
      Promise.all(promises).then(data=>{
        let msg = this.$t("bilan_circuit.updated");
        this.$router.replace({ path: `/bilan/circuit/${data[0].ceiId}/details`, query: { msg: msg } });
      });
    },
    copyRow(index) {
      let row = this.current[index];
      this.copy.data = {
        nbConduite: row.nbConduite,
        lineaireTotal: row.lineaireTotal,
        lineaireSalage: row.lineaireSalage,
        surfaceSalage: row.surfaceSalage,
        dureeEstimeTraitementAsString: row.dureeEstimeTraitementAsString,
        dureeEstimeParcoursAsString: row.dureeEstimeParcoursAsString,
        tonnageEstimeSel: row.tonnageEstimeSel,
        capaciteSaleuse: row.capaciteSaleuse
      }
      this.$toast.add({severity:'success', summary: this.$t("form.row_copied"), life: 1000});
    },
    pasteRow(index) {
      if (this.copy.data) {
        let row = this.current[index];
        row.nbConduite = this.copy.data.nbConduite;
        row.lineaireTotal = this.copy.data.lineaireTotal;
        row.lineaireSalage = this.copy.data.lineaireSalage;
        row.surfaceSalage = this.copy.data.surfaceSalage;
        row.dureeEstimeTraitementAsString = this.copy.data.dureeEstimeTraitementAsString;
        row.dureeEstimeParcoursAsString = this.copy.data.dureeEstimeParcoursAsString;
        row.tonnageEstimeSel = this.copy.data.tonnageEstimeSel;
        row.capaciteSaleuse = this.copy.data.capaciteSaleuse;
        this.$toast.add({severity: 'success', summary: this.$t("form.row_pasted"), life: 1000});
      } else {
        this.$toast.add({severity: 'error', summary: this.$t("form.no_row_copied"), life: 3000});
      }
    }
  }
}
</script>

<template v-if=!roleLoading>
  <Toast/>
  <AkFormView :ref="ref" :title="$t('bilan.update')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkInputText
            :disabled=true
            v-model=currentGeneral.ager
            :submitted=this.submitted
            class-name="col-md-4"
            :label="$t('ager_label')"/>
          <AkInputText
            :disabled=true
            v-model=currentGeneral.uer
            :submitted=this.submitted
            class-name="col-md-4"
            :label="$t('uer_label')"/>
          <AkInputText
            :disabled=true
            v-model=currentGeneral.cei
            :submitted=this.submitted
            class-name="col-md-4"
            :label="$t('cei_label')"/>
        </div>
  
        <div class="mt-2 row">
          <BilanCircuit v-for="(row, index) in current" :key="index" :row="row" :index="index" ref="panels" @copyRow="copyRow" @pasteRow="pasteRow"/>
        </div>
        
        <div class="float-right">
          <button v-if=this.canEditCircuit() @click=update() class="btn btn-primary" style="margin-left: 10px">{{ $t('update') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>